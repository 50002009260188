<script lang="ts">
  import { didInitialLoad } from "@/stores/authStore"
  import AuthenticationButton from "./AuthenticationButton.svelte"
  import DarkModeButton from "./DarkModeButton.svelte"
</script>

<div class="top-bar">
  <div class="top-bar-inner">
    <div class="match-height">
      <a class="listable match-height" href="/#">
        <img src="/logo.svg" alt="Listable logo" class="logo-main" />
        <span>Listable</span>
      </a>
    </div>
    <div>
      {#if $didInitialLoad}
        <DarkModeButton />
        <AuthenticationButton />
      {/if}
    </div>
  </div>
</div>

<style>
  .top-bar {
    position: fixed;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    background: var(--primary-bg);
    padding: var(--spacing-md);
    border-bottom: 2px solid var(--primary-accent-light);
  }
  .top-bar-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    align-items: center;
  }
  .logo-main {
    height: 100%;
  }
  .listable {
    font-family: "Sarabun", sans-serif;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 25px;
    gap: var(--spacing-md);
    height: 2em;
    user-select: none;
  }
  .match-height {
    height: 100%;
  }
</style>
