<script lang="ts">
  import RouterView from "./lib/RouterView/index.ts"
  import AuthView from "./views/AuthView.svelte"
  import AuthCallbackView from "./views/AuthCallbackView.svelte"
  import SheetRedirectView from "./views/SheetRedirectView.svelte"
  import DataView from "./views/DataView/index.ts"
  import HomeView from "./views/HomeView/index.ts"
  import NotFound from "./views/NotFound.svelte"
  import InternalRouter from "./views/InternalRouter/index.ts"
  import { onMount } from "svelte"
  import { postCheckAuth } from "./stores/authStore.ts"
  import {
    bottomRightToastManager,
    topCenterToastManager,
    ToastManager,
  } from "@/lib/ToastManager"
  import type { Route } from "./lib/RouterView/types.ts"
  import ItemPage from "./views/ItemPage/index.ts"
  import Dashboard from "./views/Dashboard/index.ts"
  import SidepanelManager from "./lib/sidepanelManager/SidepanelManager.svelte"
  import { sidepanelManager } from "./lib/sidepanels/index.ts"
  import ModalManager from "./lib/modalManager/ModalManager.svelte"
  import { globalModalManager } from "./lib/modals/index.ts"
  import { apiClient } from "./api/index.ts"
  import PrivacyRequest from "./views/PrivacyRequest.svelte"
  import Privacy from "./views/Privacy.svelte"

  export let isMobileApp: boolean = false

  const routes: Route[] = [
    {
      pattern: /^\/$/,
      component: isMobileApp ? Dashboard : HomeView,
      dynamicComponent: undefined,
    },
    {
      pattern:
        /^\/d\/([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})\//,
      component: DataView,
      dynamicComponent: undefined,
    },
    {
      pattern: /^\/auth/,
      component: AuthView,
      dynamicComponent: undefined,
    },
    {
      pattern: /^\/callback/,
      component: AuthCallbackView,
      dynamicComponent: undefined,
    },
    {
      pattern:
        /^\/s\/([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/,
      component: ItemPage,
      dynamicComponent: undefined,
    },
    {
      pattern:
        /^\/list\/([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})$/,
      component: SheetRedirectView,
      dynamicComponent: undefined,
    },
    {
      pattern: /\/dash/,
      component: Dashboard,
      dynamicComponent: undefined,
    },
    {
      pattern: /\/privacy\/request/,
      component: PrivacyRequest,
      dynamicComponent: undefined,
    },
    {
      pattern: /\/privacy/,
      component: Privacy,
      dynamicComponent: undefined,
    },
    {
      pattern: /^\/_internal/,
      component: InternalRouter,
      dynamicComponent: undefined,
    },
    {
      pattern: /^/,
      component: NotFound,
      dynamicComponent: undefined,
    },
  ]

  onMount(async () => {
    const resp = await apiClient.call("getOrAddUser", null)
    postCheckAuth(resp)
  })

  const { toasts: bottomRightToasts, position: bottomRightToastsPosition } =
    bottomRightToastManager
  const { toasts: topCenterToasts, position: topCenterToastsPosition } =
    topCenterToastManager
</script>

<ToastManager
  toasts={$bottomRightToasts}
  position={bottomRightToastsPosition}
/>
<ModalManager modalManager={globalModalManager} />
<RouterView {routes} />
<SidepanelManager {sidepanelManager} />
<ToastManager toasts={$topCenterToasts} position={topCenterToastsPosition} />
