import { createSidepanelManager } from "@/lib/sidepanelManager"
import AddColumnSidepanel from "@/lib/sidepanels/AddColumnSidepanel.svelte"
import EditColumnSidepanel from "@/lib/sidepanels/EditColumnSidepanel.svelte"
import SetColumnsSidepanel from "@/lib/sidepanels/SetColumnsSidepanel.svelte"
import NewItemSidepanel from "@/lib/sidepanels/NewItemSidepanel.svelte"
import ViewEditorSidepanel from "@/lib/sidepanels/ViewEditorSidepanel.svelte"
import AddVariableModal from "@/lib/modals/AddVariableModal.svelte"
import PoiSidepanel from "@/lib/sidepanels/PoiSidepanel.svelte"
import { createModalManager } from "@/lib/modalManager"
import type { ComponentProps } from "svelte"

const sidepanelManager = createSidepanelManager()

const addColumnSidepanel = sidepanelManager.makeSidepanel({
  title: "Add Column",
  component: AddColumnSidepanel,
})

const editColumnSidepanel = sidepanelManager.makeSidepanel({
  title: "Edit Column",
  component: EditColumnSidepanel,
})

const setColumnsSidepanel = sidepanelManager.makeSidepanel({
  title: "Show/Hide Columns",
  component: SetColumnsSidepanel,
})

const modalManager = createModalManager()

const addVariableModal = modalManager.makeModal<
  string,
  ComponentProps<AddVariableModal>
>({
  title: "New Variable",
  description: "add a new global sheet variable",
  component: AddVariableModal,
})

const newItemSidepanel = sidepanelManager.makeSidepanel({
  title: "New Listing",
  nthToFocusFirst: 5,
  component: NewItemSidepanel,
})

const viewEditorSidepanel = sidepanelManager.makeSidepanel({
  title: "Filter & Group",
  component: ViewEditorSidepanel,
})

const poiSidepanel = sidepanelManager.makeSidepanel({
  title: "New Point of Interest",
  component: PoiSidepanel,
})

export const dataViewModule = {
  sidepanelManager,
  addColumnSidepanel,
  editColumnSidepanel,
  setColumnsSidepanel,
  modalManager,
  addVariableModal,
  newItemSidepanel,
  viewEditorSidepanel,
  poiSidepanel,
}

export type DataViewModule = typeof dataViewModule
