import {
  objectKeys,
  filterObjValsNonNullish,
  getBaseUrl,
} from "./util/index.ts"

const combinePathAndSearchHash = (
  path: string,
  search: PartialRecord<string, string>,
  hash?: string
) =>
  `${path}${
    objectKeys(filterObjValsNonNullish(search)).length > 0
      ? `?${new URLSearchParams(
          filterObjValsNonNullish(search) as Record<string, string>
        ).toString()}`
      : ""
  }${hash ? `#${hash}` : ""}`

export const homeRoute = () => `/`
export const dashboardRoute = () => `/dash`

export type SheetRouteBase = {
  sheetId: string
  itemId?: string
}

type MapRouteProps = SheetRouteBase & {
  selectedColumn?: string
}

export const mapRoute = ({
  sheetId,
  itemId,
  selectedColumn,
}: MapRouteProps) => {
  const u = new URL(window.location.href)
  u.pathname = `/d/${sheetId}/map`
  const params = new URLSearchParams()
  if (itemId) {
    params.set("itemId", itemId)
  }
  if (selectedColumn) {
    params.set("selectedColumn", selectedColumn)
  }
  u.search = params.toString()
  return u.pathname + u.search + u.hash
}
export const chartRoute = ({ sheetId }: { sheetId: string }) =>
  `/d/${sheetId}/chart`
export const tableRoute = ({ sheetId, itemId }: SheetRouteBase) =>
  combinePathAndSearchHash(`/d/${sheetId}/table`, { itemId })
export const listRoute = ({ sheetId, itemId }: SheetRouteBase) =>
  combinePathAndSearchHash(`/d/${sheetId}/list`, { itemId })
export const sheetRedirectRoute = ({ sheetId, itemId }: SheetRouteBase) =>
  combinePathAndSearchHash(`/list/${sheetId}`, { itemId })
export const publicUrl = (innerRoute: string) =>
  new URL(innerRoute, getBaseUrl())
export const privacyRoute = () => "/privacy"
export const privacyRequestRoute = () => "/privacy/request"

export const itemPageRoute = ({
  sheetId,
  itemId,
}: {
  sheetId: string
  itemId: string
}) => combinePathAndSearchHash(`/s/${sheetId}`, { itemId })
