<script lang="ts" context="module">
  export type AddColumnInfo = {
    name: string
    dataType: EnumT.DataType
    formatSpec: FormatSpec | undefined
    generatorSpec: string | undefined
  }
</script>

<script lang="ts">
  import {
    defaultFormatSpec,
    userDataTypeDataMap,
    type UserDataType,
  } from "@/util/columns"
  import { builtins } from "@shared/lispable/functions.ts"
  import {
    assertParsedItemType,
    ParsedItemType,
  } from "@shared/lispable/parsing"
  import { tryToParse } from "@shared/lispable/script"
  import type { EnumT } from "@shared/schema/index.ts"

  import type { FormatSpec } from "@shared/types.ts"
  import { assertDefined } from "@shared/util/index.ts"
  import Button from "../Button.svelte"
  import type { OpenAddVariableModal } from "../generatorSpec/types.ts"
  import SidepanelContent from "./SidepanelContent.svelte"
  import ColumnEditor, { ColumnType } from "../ColumnEditor.svelte"
  import { sheetContentFactory } from "@/stores/sheetStore.ts"

  export let sheetId: string
  export let accept: (info: AddColumnInfo) => void
  export let openAddVariableModal: OpenAddVariableModal

  let userDataType: UserDataType | undefined = undefined
  let formatSpec: FormatSpec | undefined = undefined
  let generatorSpec: string = ""
  let name: string = ""
  let columnType: ColumnType | undefined = ColumnType.userInput
  let premiumFuncName: string | undefined = undefined
  let premiumColumnValidated: boolean = false

  $: premiumFunc = premiumFuncName
    ? assertDefined(builtins[premiumFuncName as keyof typeof builtins])
    : undefined
  $: dataType = userDataType ? userDataTypeDataMap[userDataType] : undefined
  $: parsedGeneratorSpec = tryToParse(generatorSpec)
  $: sheetContent = $sheetContentFactory(sheetId)

  const addColumn = () => {
    if (columnType === ColumnType.userInput) {
      accept({
        name,
        dataType: assertDefined(dataType),
        formatSpec: { ...defaultFormatSpec(userDataType), ...formatSpec },
        generatorSpec: generatorSpec !== "" ? generatorSpec : undefined,
      })
    } else if (columnType === ColumnType.advanced) {
      assertParsedItemType(parsedGeneratorSpec, ParsedItemType.funcCall)
      const func = assertDefined(
        builtins[parsedGeneratorSpec.funcName as keyof typeof builtins]
      )
      accept({
        name,
        dataType: func.dataType,
        formatSpec: func.formatSpec,
        generatorSpec,
      })
    } else if (columnType === ColumnType.premium) {
      const func = assertDefined(premiumFunc)
      accept({
        name,
        dataType: func.dataType,
        formatSpec: func.formatSpec,
        generatorSpec,
      })
    }
  }

  $: acceptDisabled = (() => {
    if (name.trim() === "") {
      return true
    }
    if (columnType === ColumnType.userInput) {
      return !dataType
    } else if (columnType === ColumnType.advanced) {
      if (parsedGeneratorSpec?.type !== ParsedItemType.funcCall) {
        return true
      }
      const func =
        builtins[parsedGeneratorSpec.funcName as keyof typeof builtins]
      if (!func) {
        return true
      }
      return func.args.length !== parsedGeneratorSpec.args.length
    } else if (columnType === ColumnType.premium) {
      return !generatorSpec || !premiumColumnValidated
    }
  })()
</script>

<SidepanelContent>
  <div class="content">
    {#if sheetContent}
      <ColumnEditor
        bind:generatorSpec
        bind:premiumColumnValidated
        bind:columnType
        bind:name
        bind:formatSpec
        bind:userDataType
        bind:premiumFuncName
        {sheetContent}
        {openAddVariableModal}
      />
    {/if}
  </div>
  <div slot="footer" class="footer">
    <Button variant="success" disabled={acceptDisabled} on:click={addColumn}
      >Accept</Button
    >
  </div>
</SidepanelContent>

<style>
  @media only screen and (min-width: 770px) {
    .content {
      min-width: 450px;
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
</style>
