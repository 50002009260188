<script lang="ts">
  import CompareView from "@/lib/CompareView.svelte"
  import ItemTagLine from "@/lib/ItemTagLine.svelte"
  import QuickFacts from "@/lib/QuickFacts.svelte"
  import { sheetContentFactory, storeConnected } from "@/stores/sheetStore"
  import { EnumT } from "@shared/schema/index.ts"
  import { buildFieldValueLookup, getParsedValueByField } from "@shared/sheet"
  import { onMount, type ComponentType } from "svelte"
  import ItemCarousel from "./ItemCarousel.svelte"
  import ItemHeader from "./ItemHeader.svelte"
  import { getSocket } from "@/client/socket"
  import ItemNotes from "./ItemNotes.svelte"
  import Button from "@/lib/Button.svelte"
  import { t } from "@/translation/index.ts"
  import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"

  let ItemLocation: ComponentType | undefined

  export let query: URLSearchParams | undefined
  export let matches: [string, string | undefined]

  $: itemId = query?.get("itemId")
  $: sheetId = matches[1]

  $: if (sheetId && $storeConnected) {
    getSocket().then((socket) =>
      sheetId ? socket.emit("joinSheet", { sheetId }) : undefined
    )
  }

  $: sheetContent = sheetId ? $sheetContentFactory(sheetId) : undefined

  $: fieldValueLookup =
    sheetContent && itemId
      ? buildFieldValueLookup({ sheetContent: sheetContent, rowId: itemId })
      : undefined

  $: location =
    itemId && sheetContent
      ? getParsedValueByField<EnumT.Field.location>({
          sheetContent: sheetContent,
          rowId: itemId,
          field: EnumT.Field.location,
        })
      : undefined

  onMount(async () => {
    ItemLocation = (await import("./ItemLocation.svelte"))
      .default as unknown as ComponentType
  })

  const goBack = () => {
    window.history.back()
  }
</script>

<div class="page">
  <div class="content">
    <Button on:click={goBack} iconLeft={faChevronLeft}>{t("Back")}</Button>
    {#if itemId && sheetContent && fieldValueLookup}
      <div class="vertical-section">
        <ItemHeader {sheetContent} rowId={itemId} />
      </div>
      <div class="vertical-section">
        <div class="carousel-wrapper">
          <ItemCarousel {sheetContent} rowId={itemId} />
        </div>
      </div>
      <div class="vertical-section x-padding">
        <ItemTagLine
          pageOptions={["map", "table", "list"]}
          {sheetContent}
          rowId={itemId}
          {fieldValueLookup}
          showRating
        />
      </div>
      <div class="quick-facts-wrapper vertical-section x-padding">
        <QuickFacts {fieldValueLookup} />
      </div>
      <ItemNotes {sheetContent} rowId={itemId} />
      <div class="vertical-section">
        {#if ItemLocation && location}
          <div class="location-wrapper">
            <svelte:component
              this={ItemLocation}
              {sheetContent}
              rowId={itemId}
            />
          </div>
        {/if}
      </div>
      <div class="vertical-section details-table">
        <CompareView {sheetContent} layoutFixed itemIds={[itemId]} />
      </div>
    {/if}
  </div>
</div>

<style>
  .carousel-wrapper {
    box-shadow: 4px 8px 15px 0px rgba(0, 0, 0, 0.45);
    overflow: hidden;
    border-radius: var(--default-rounding);
  }
  .vertical-section {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .content {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  .page {
    background-color: var(--secondary-bg);
    padding-top: 25px;
    padding-bottom: 25px;
    min-height: 100%;
  }
  .location-wrapper {
    aspect-ratio: 1 / 0.5;
    margin-left: auto;
    border-radius: var(--default-rounding);
    overflow: hidden;
    box-shadow: 4px 8px 15px 0px rgba(0, 0, 0, 0.45);
  }
  @media only screen and (max-width: 769px) {
    .location-wrapper {
      height: 50vh;
      aspect-ratio: unset;
    }
    .page {
      padding-bottom: 0;
    }
  }
  .x-padding {
    margin-left: 16px;
    margin-right: 16px;
  }
  .quick-facts-wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
    font-size: 20px;
    flex-wrap: wrap;
  }
  .details-table {
    box-shadow: 4px 8px 15px 0px rgba(0, 0, 0, 0.45);
    border-radius: var(--default-rounding);
    padding: 8px;
  }
</style>
