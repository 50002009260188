<script lang="ts">
  import SidepanelContent from "./SidepanelContent.svelte"
  import Button from "../Button.svelte"
  import type { CellValue, ExtractorKey } from "@shared/types.ts"
  import type { EnumT } from "@shared/schema"
  import ExpandedView from "../ExpandedView.svelte"
  import type { GenericDataValue, PartialFieldValueLookup } from "@shared/types"
  import {
    newTypedArray,
    newTypedObject,
    objectEntries,
    objectKeys,
  } from "@shared/util"
  import { fade } from "svelte/transition"
  import AddItemInput from "../sheet/AddItemInput.svelte"
  import { listingTypeInfoMap } from "@shared/data/defaults"
  import Choosable from "../controls/Choosable.svelte"
  import { apiClient } from "@/api"
  import TabsHeader from "../TabsHeader.svelte"
  import { t } from "@/translation/index.ts"
  import SpacingWrapper from "../layout/SpacingWrapper.svelte"
  import AddManually from "../AddManually.svelte"
  import { findDefaultUrlColumn } from "@/util"
  import { sheetContentFactory } from "@/stores/sheetStore"

  type EnhancedExtractedItem = {
    extractorKey: { source: EnumT.Source; sourceKey: string }
    fieldValueLookup: PartialFieldValueLookup
    chosen: boolean
  }

  export let sheetId: string
  export let listingType: EnumT.ListingType | null | undefined
  export let onAccept: (acceptVal: {
    keysToAdd?: ExtractorKey[]
    columnData?: PartialRecord<string, GenericDataValue>
  }) => void

  let value: string = ""
  let items: EnhancedExtractedItem[] = []
  let loading: boolean = false
  let didOneFetch: boolean = false
  let selectedIndex = 0

  const onPreview = async () => {
    loading = true
    items = []
    const results = await apiClient.call("fetchInput", { input: value })
    const newItems = newTypedArray<EnhancedExtractedItem>()
    for (const extractedItem of results.extractedItems) {
      const fieldValueLookup = newTypedObject<EnumT.Field, GenericDataValue>()
      for (const extractedData of extractedItem.data) {
        fieldValueLookup[extractedData.field] = extractedData.value
      }
      newItems.push({
        fieldValueLookup: fieldValueLookup as PartialFieldValueLookup,
        extractorKey: {
          source: extractedItem.source,
          sourceKey: extractedItem.sourceKey,
        },
        chosen: true,
      })
    }
    items = newItems
    loading = false
    didOneFetch = true
  }

  $: chosenItems = items.filter((item) => item.chosen)

  $: keysToAdd = chosenItems.map((item) => item.extractorKey)

  const submit = async () => {
    onAccept({ keysToAdd })
  }

  const setChosen = (i: number, chosen: boolean) => () =>
    items[i] ? (items[i].chosen = chosen) : undefined

  $: addButtonText = (() => {
    if (chosenItems.length > 1) {
      if (listingType) {
        return `Add ${keysToAdd.length} ${listingTypeInfoMap[listingType].name.plural}`
      } else {
        return `Add ${keysToAdd.length} Listings`
      }
    } else if (chosenItems.length === 1) {
      if (listingType) {
        return `Add ${listingTypeInfoMap[listingType].name.singular}`
      } else {
        return `Add Listing`
      }
    } else {
      return ""
    }
  })()

  let manualValue: PartialRecord<string, CellValue> = {}

  const submitManual = () => {
    const columnData = Object.fromEntries(
      objectEntries(manualValue).map(([colId, cellValue]) => [
        colId,
        cellValue.userInputData,
      ])
    )
    onAccept({ columnData })
  }

  $: sheetContent = $sheetContentFactory(sheetId)
  const retryManually = () => {
    if (sheetContent != null) {
      const urlColumnId = findDefaultUrlColumn(
        sheetContent.columns,
        sheetContent.columnDefinitions
      )
      if (urlColumnId != null) {
        manualValue[urlColumnId] = { userInputData: value }
      }
    }
    selectedIndex = 1
  }

  const selectAll = () => {
    items.map((item) => (item.chosen = true))
    items = items
  }

  const deselectAll = () => {
    items.map((item) => (item.chosen = false))
    items = items
  }
</script>

<SidepanelContent>
  <div class="content">
    <TabsHeader
      options={[{ label: t("Add by URL") }, { label: t("Add Manually") }]}
      bind:selectedIndex
    />
    <SpacingWrapper top>
      {#if selectedIndex === 0}
        <div class="add-item-input-wrapper">
          <AddItemInput on:submit={onPreview} bind:value {listingType} />
        </div>
        <div class="preview-button-wrapper center-button-wrapper">
          <Button variant="action" size="large" on:click={onPreview}
            >Fetch</Button
          >
        </div>
        {#if loading}
          <div transition:fade={{ duration: 150 }} class="loading-wrapper">
            Fetching Data...
            <div class="loading-bar"><div class="loading-bar-inner" /></div>
          </div>
        {:else if items.length === 0 && didOneFetch && !loading}
          <div transition:fade={{ duration: 150 }} class="empty-message">
            No results found<br />
            <SpacingWrapper top>
              <Button on:click={retryManually}>Add Manually</Button>
            </SpacingWrapper>
          </div>
        {:else}
          <div class="items">
            {#if items.length > 0}
              <Button on:click={selectAll}>{t("Select All")}</Button>
              <Button on:click={deselectAll}>{t("Deselect All")}</Button>
            {/if}
            {#each items as item, i}
              <div transition:fade={{ duration: 150 }} class="expanded-wrapper">
                <Choosable
                  clearable={items.length > 1}
                  chosen={item.chosen}
                  groupName="items-to-add"
                  ariaDescription="Item to add"
                  on:choose={setChosen(i, true)}
                  on:unchoose={setChosen(i, false)}
                >
                  <ExpandedView
                    rowId={undefined}
                    sheetContent={undefined}
                    fieldValueLookup={item.fieldValueLookup}
                  />
                </Choosable>
              </div>
            {/each}
          </div>
        {/if}
      {:else if selectedIndex === 1}
        <AddManually {sheetId} bind:value={manualValue} />
      {/if}
    </SpacingWrapper>
  </div>
  <div slot="footer" class="footer">
    {#if items.length > 0 && selectedIndex === 0}
      <div class="center-button-wrapper">
        {#if chosenItems.length > 0}
          <Button
            variant="success"
            size="large"
            disabled={items.length === 0}
            on:click={submit}>{addButtonText}</Button
          >
        {/if}
      </div>
    {:else if selectedIndex === 1 && objectKeys(manualValue).length > 0}
      <div class="center-button-wrapper">
        <Button variant="success" size="large" on:click={submitManual}
          >Submit</Button
        >
      </div>
    {/if}
  </div>
</SidepanelContent>

<style>
  @media only screen and (min-width: 770px) {
    .content {
      width: 550px;
      min-width: 550px;
    }
  }
  .preview-button-wrapper {
    margin-top: 10px;
  }
  .center-button-wrapper {
    display: flex;
    justify-content: center;
  }
  .expanded-wrapper {
    background-color: var(--primary-accent);
    transition: background-color 0.25s ease-in-out;
    border-radius: 10px;
    margin: var(--default-margin);
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .items {
    max-width: 100%;
  }
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .loading-wrapper {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    font-size: 20px;
    font-weight: bold;
  }
  .loading-bar {
    width: 100%;
    height: 5px;
    background-color: var(--action-bg);
    border-radius: var(--default-rounding);
    overflow: hidden;
    margin-top: 15px;
  }
  .loading-bar-inner {
    width: 10%;
    animation-name: loader;
    animation-iteration-count: infinite;
    animation-duration: 1.1s;
    background-color: var(--action-accent);
    height: 5px;
  }
  .empty-message {
    margin-top: 10px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
  @keyframes loader {
    from {
      width: 10%;
      margin-left: -10%;
    }
    30% {
      width: 50%;
    }
    50% {
      width: 50%;
    }
    75% {
      width: 10%;
    }
    to {
      width: 10%;
      margin-left: 110%;
    }
  }
  .add-item-input-wrapper {
    /* Prevent content below from jumping when source is detected (shitty fix) */
    height: 120px;
  }
</style>
