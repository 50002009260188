<script lang="ts">
  import { debounce, enterWrapper, keyPressWrapper } from "@/util"
  import type { EnumT } from "@shared/schema"
  import SourceCard from "../SourceCard.svelte"
  import { slideAndFade } from "@/lib/transitions"
  import FixedLayoutHorizontal from "../layout/FixedLayoutHorizontal.svelte"
  import Icon from "../Icon.svelte"
  import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons"
  import SpacingWrapper from "../layout/SpacingWrapper.svelte"
  import { listingTypeInfoMap } from "@shared/data/defaults"
  import { apiClient } from "@/api"
  import { createEventDispatcher } from "svelte"

  export let value: string
  export let listingType: EnumT.ListingType | null | undefined

  let source: EnumT.Source | undefined
  let inputElem: HTMLInputElement | undefined
  let hint: string | undefined

  const dispatch = createEventDispatcher<{ submit: undefined }>()

  const doSubmit = () => dispatch("submit")

  const detectSource = async () => {
    const { data } = await apiClient.call("checkInput", { input: value })
    if (!data) {
      source = undefined

      return
    }
    source = data?.source
    hint = data?.response.message
  }

  const debouncedDetectSource = debounce(detectSource)

  $: if (value) {
    debouncedDetectSource()
  } else {
    source = undefined
  }

  const clear = () => (value = "")

  const focusInput = () => inputElem?.focus()

  $: placeholderText = listingType
    ? `${listingTypeInfoMap[listingType].emoji} Paste a link to ${
        listingTypeInfoMap[listingType].name.indefiniteArticle
      } ${listingTypeInfoMap[listingType].name.singular.toLowerCase()}!`
    : `➡️ Paste a link to something!`
</script>

<div class="wrapper" on:click={focusInput}>
  <div class="input-wrapper">
    <FixedLayoutHorizontal flexRight>
      <input
        type="text"
        bind:this={inputElem}
        bind:value
        placeholder={placeholderText}
        on:keypress={enterWrapper(doSubmit)}
      />
      <div slot="right" class="input-right">
        {#if value}<div
            tabindex="0"
            on:click={clear}
            on:keypress={keyPressWrapper(clear)}
            class="clear-wrapper"
          >
            <SpacingWrapper sides={["left", "right"]}
              ><Icon icon={faXmarkCircle} /></SpacingWrapper
            >
          </div>{/if}
      </div>
    </FixedLayoutHorizontal>
    <div class="underline-wrapper">
      <div class="underline-placeholder" />
      <div class="underline" />
    </div>
  </div>
  {#if hint}
    {hint}
  {/if}
  {#if source}
    <div class="source-info" transition:slideAndFade={{ duration: 200 }}>
      <SourceCard {source} nofocus readonly />
    </div>
  {/if}
</div>

<style>
  .wrapper {
    background: var(--secondary-bg);
    color: var(--secondary-fg);
    padding: 16px;
    border-radius: var(--default-rounding);
    transition: height 0.5s ease-in-out;
  }
  .underline {
    transition: all 0.2s ease-in-out;
    position: relative;
    width: 0;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2px;
    z-index: 1;
  }
  .input-wrapper:focus-within .underline {
    width: 100%;
    background-color: var(--action-accent);
  }
  .underline-wrapper {
    position: relative;
    height: 3px;
  }
  .underline-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--primary-accent);
  }
  .input-right {
    display: flex;
  }
  input {
    background: none;
    border: none;
    outline: none;
    font-size: 20px;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    color: var(--primary-fg);
  }
  .source-info {
    margin-top: 8px;
  }
  .clear-wrapper {
    align-self: center;
    cursor: pointer;
  }
</style>
