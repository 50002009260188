<script lang="ts">
  import DataTable from "@/lib/DataTable"
  import { sheetContentFactory } from "@/stores/sheetStore"
  import { getColumnDefinitionForCol } from "@shared/sheet"
  import {
    type CellKey,
    type CellValue,
    CellState,
    type ElementOf,
    type GenericDataValue,
    type ProcessedSheetContent,
  } from "@shared/types"
  import { newTypedObject, objectKeys } from "@shared/util"
  import DataCellWrapper from "./DataCellWrapper.svelte"
  import { readable } from "svelte/store"
  import { ACTIONS_AXIS_ID } from "./DataTable/constants"
  import type { CellDataFactory, ColumnHeaderDataFactory } from "./types"
  import type { ColAction, RowAction } from "./DataTable/types"
  import { canManuallyAddField } from "@shared/data/defaults"

  export let sheetId: string

  type SimpleColumn = {
    name: string
    colDef: ElementOf<ProcessedSheetContent["columnDefinitions"]>
  }

  const fakeRowId = crypto.randomUUID()
  const rows = [ACTIONS_AXIS_ID, fakeRowId]
  $: sheetContent = $sheetContentFactory(sheetId)
  $: columnLookup = (() => {
    const lookup = newTypedObject<string, SimpleColumn>()
    if (sheetContent == null) {
      return lookup
    }
    for (const column of sheetContent.columns) {
      const colId = column.id
      const name = column.name
      const colDef = getColumnDefinitionForCol({ sheetContent, colId })
      if (colDef.field == null || !canManuallyAddField(colDef.field)) {
        continue
      }
      lookup[colId] = {
        name,
        colDef,
      }
    }
    return lookup
  })()

  $: columns = objectKeys(columnLookup)

  export let value = newTypedObject<string, CellValue>()

  $: cellDataFactory = readable((ck: CellKey) => ({
    cellValue: value[ck.colId],
    cellState: CellState.default,
    colDef: columnLookup[ck.colId]!.colDef,
  })) as CellDataFactory

  $: columnHeaderDataFactory = readable((colId: string) =>
    columnLookup[colId] ? { title: columnLookup[colId]!.name } : undefined
  ) as ColumnHeaderDataFactory

  const setDataValue = (cellKey: CellKey, v: GenericDataValue | undefined) =>
    (value[cellKey.colId] = { userInputData: v })

  $: cellDataProvider = (cellKey: CellKey) => ({
    onChangeCB: (v: GenericDataValue | undefined) => {
      setDataValue(cellKey, v)
    },
    cellDataFactory,
    columnHeaderDataFactory,
    hideSortControls: true,
    rowActions: [] as RowAction[],
    colActions: [] as ColAction[],
    sheetId: undefined as string | undefined,
    internal: {
      skipWrapWithTd: cellKey.rowId === ACTIONS_AXIS_ID,
    },
  })
</script>

<div>
  <DataTable
    stickyHeader
    transpose
    className="data-table"
    {columns}
    {rows}
    fullwidth
    {cellDataProvider}
    cellRenderer={DataCellWrapper}
  />
</div>
