<script lang="ts">
  import type { Writable } from "svelte/store"
  import SidepanelContent from "./SidepanelContent.svelte"
  import MarkdownEditor from "../MarkdownEditor.svelte"
  import SpacingWrapper from "../layout/SpacingWrapper.svelte"

  export let valueStore: Writable<string>

  let value: string = ""

  const setValueInner = (val: string) => (value = val)

  $: valueStore.subscribe(setValueInner)

  const updateStore = (val: string) => {
    valueStore.update(() => val)
  }

  $: updateStore(value)
</script>

<SidepanelContent>
  <MarkdownEditor bind:value />
  <SpacingWrapper size="md" top>
    Your changes are being saved automatically.
  </SpacingWrapper>
</SidepanelContent>
