import z from "zod"
import { EnumT } from "../schema"

export const ZSheetInfo = z.object({
  sheetProperties: z.object({
    id: z.string().uuid(),
    name: z.string(),
    listingType: z.nativeEnum(EnumT.ListingType).optional(),
    created: z.coerce.date(),
    updated: z.coerce.date(),
  }),
  lastActivity: z.coerce.date(),
  numItems: z.number(),
  thumbnails: z.array(z.string()),
})
export type SheetInfo = z.infer<typeof ZSheetInfo>
