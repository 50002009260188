import { createSidepanelManager } from "../sidepanelManager/index.ts"
import EditMarkdownSidepanel from "@/lib/sidepanels/EditMarkdownSidepanel.svelte"
import ViewMarkdownSidepanel from "@/lib/sidepanels/ViewMarkdownSidepanel.svelte"

export const sidepanelManager = createSidepanelManager()

export const globalEditMarkdownSidepanel = sidepanelManager.makeSidepanel({
  component: EditMarkdownSidepanel,
  title: "Edit Notes",
})

export const globalViewMarkdownSidepanel = sidepanelManager.makeSidepanel({
  component: ViewMarkdownSidepanel,
  title: "Notes",
})
