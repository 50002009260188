<script lang="ts">
  import Icon from "@/lib/Icon.svelte"
  import SheetLink from "@/lib/SheetLink.svelte"
  import { timeAgo } from "@/util"
  import { faList } from "@fortawesome/free-solid-svg-icons"
  import type { SheetInfo } from "@shared/api/sheet.ts"
  import { listingTypeInfoMap } from "@shared/data/defaults"
  import { DateTime } from "luxon"

  export let sheetInfo: SheetInfo

  $: thumbnail = sheetInfo.thumbnails?.[0]
</script>

<SheetLink sheetId={sheetInfo.sheetProperties.id}>
  <div class="wrapper">
    <div class="top-line">
      {#if thumbnail}
        <img
          class="thumbnail"
          alt={`thumbnail for ${sheetInfo.sheetProperties.name}`}
          src={thumbnail}
        />
      {:else if sheetInfo.sheetProperties.listingType != null}
        <div class="thumbnail thumbnail-placeholder">
          {listingTypeInfoMap[sheetInfo.sheetProperties.listingType].emoji}
        </div>
      {:else}
        <div class="thumbnail thumbnail-placeholder">
          <Icon icon={faList} />
        </div>
      {/if}
      <span class="name">{sheetInfo.sheetProperties.name}</span>
      <div class="open-link">
        <span class="modified">
          Modified {timeAgo(
            DateTime.fromJSDate(sheetInfo.lastActivity).toSeconds()
          )}
        </span>
        <SheetLink component="button" sheetId={sheetInfo.sheetProperties.id}
          >Open</SheetLink
        >
      </div>
    </div>
  </div>
</SheetLink>

<style>
  .wrapper {
    border-radius: 16px;
    padding: 8px;
    background-color: var(--secondary-bg);
    margin-top: var(--spacing-xl);
    overflow: hidden;
  }
  .wrapper:hover {
    filter: brightness(0.95);
    box-shadow: 0px 4px 8px 0px rgba(80, 80, 80, 0.45);
  }
  .thumbnail {
    width: 75px;
    margin-left: -8px;
    margin-top: -8px;
    margin-bottom: -8px;
    flex-shrink: 0;
    display: none;
    aspect-ratio: 1 / 1;
  }
  .top-line {
    display: flex;
    flex-direction: row;
  }
  .modified {
    flex-shrink: 0;
    font-weight: 200;
    padding-right: 8px;
    font-size: 12px;
    display: none;
  }
  .name {
    font-size: 18px;
    font-weight: 400;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: center;
    padding-left: 16px;
  }
  .open-link {
    white-space: pre;
    align-self: center;
    justify-self: flex-end;
    padding: 8px;
    flex-shrink: 0;
  }
  .thumbnail-placeholder {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    background-color: var(--secondary-accent);
  }
  @media only screen and (min-width: 770px) {
    .thumbnail {
      display: unset;
    }
    .thumbnail-placeholder {
      display: flex;
    }
    .modified {
      display: unset;
    }
  }
</style>
