<script lang="ts">
  import SidepanelContent from "./SidepanelContent.svelte"
  import { Carta, Markdown } from "carta-md"
  import DOMPurify from "isomorphic-dompurify"

  export let value: string = ""

  const carta = new Carta({ sanitizer: DOMPurify.sanitize })
</script>

<SidepanelContent>
  <Markdown {value} {carta} />
</SidepanelContent>
