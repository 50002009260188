<script lang="ts">
  import { classNames } from "@/util"
  import type { Side } from "./types.ts"
  import type { StandardSize } from "@/types/index.ts"

  export let axis: "y" | "x" | undefined = undefined
  export let sides: Side[] = ["left", "right", "top", "bottom"]
  export let size: StandardSize = "md"
  export let top: boolean | undefined = undefined
  export let bottom: boolean | undefined = undefined
  export let left: boolean | undefined = undefined
  export let right: boolean | undefined = undefined

  $: computedSides = (() => {
    const resolved = new Set<Side>()
    if (top) resolved.add("top")
    if (bottom) resolved.add("bottom")
    if (left) resolved.add("left")
    if (right) resolved.add("right")
    if (axis === "y") {
      resolved.add("top")
      resolved.add("bottom")
    } else if (axis === "x") {
      resolved.add("left")
      resolved.add("right")
    } else if (resolved.size === 0) {
      return sides
    }
    return [...resolved]
  })()

  let spacingElem: HTMLDivElement | undefined

  $: spacingElem?.style.setProperty("--margin-width", `var(--spacing-${size})`)
</script>

<div bind:this={spacingElem} class={classNames(...computedSides)}><slot /></div>

<style>
  :root {
    --margin-width: var(--spacing-md);
  }
  .left {
    margin-left: var(--margin-width);
  }
  .right {
    margin-right: var(--margin-width);
  }
  .top {
    margin-top: var(--margin-width);
  }
  .bottom {
    margin-bottom: var(--margin-width);
  }
</style>
