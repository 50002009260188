<script lang="ts">
  import Button from "@/lib/Button.svelte"
  import Input from "@/lib/Input.svelte"
  import { navigateTo } from "@/lib/RouterView"
  import { listRoute, tableRoute } from "@/routes"
  import { isMobile } from "@/util"
  import { globalChooseListingTypeModal } from "@/lib/modals"
  import { faPlus } from "@fortawesome/free-solid-svg-icons"
  import type { SheetInfo } from "@shared/api/sheet.ts"
  import { onMount } from "svelte"
  import SheetCard from "./SheetCard.svelte"
  import type { EnumT } from "@shared/schema"
  import { defaultSheetNameForListingType } from "@shared/data/defaults"
  import { apiClient } from "@/api"
  import MainNav from "@/lib/MainNav.svelte"

  let sheets: SheetInfo[] | undefined = undefined
  let query: string = ""

  const refreshSheets = async () => {
    sheets = (await apiClient.call("getUserSheets", null)).sheets
  }

  const createSheet = () => {
    globalChooseListingTypeModal.open({
      props: {},
      onAccept: async (listingType: EnumT.ListingType | null) => {
        const { sheetId } = await apiClient.call("createSheet", {
          listingType,
          name: listingType
            ? defaultSheetNameForListingType(listingType)
            : undefined,
        })
        const to = isMobile() ? listRoute({ sheetId }) : tableRoute({ sheetId })
        navigateTo(to)
      },
    })
  }

  $: filteredSheets = sheets?.filter((sheet) =>
    sheet.sheetProperties.name.toLowerCase().includes(query.toLowerCase())
  )

  onMount(() => {
    void refreshSheets()
  })
</script>

<main>
  <MainNav />
  <div class="top-line">
    <div class="page-title">Your Lists</div>
    <div class="filter-line">
      <div class="filter-input-wrapper">
        <Input
          fullwidth
          size="large"
          bind:value={query}
          type="text"
          placeholder="Search"
        />
      </div>
      <div class="new-button-wrapper">
        <Button iconLeft={faPlus} variant="action" on:click={createSheet}
          >New</Button
        >
      </div>
    </div>
  </div>
  {#if filteredSheets}
    {#each filteredSheets as sheetInfo}
      <SheetCard {sheetInfo} />
    {/each}
  {/if}
</main>

<style>
  main {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
  }
  .page-title {
    font-size: 28px;
    padding-top: 20px;
  }
  .top-line {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
    margin-top: 66px;
  }
  .new-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .filter-line {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-lg);
  }
</style>
