<script lang="ts">
  import RouterLink from "@/lib/RouterView/RouterLink.svelte"
  import { classNames } from "@/util"
  import { COMMON_ANIMATION_OPTIONS } from "@/util/animation"

  import { animate, scroll } from "motion"
  import { onMount } from "svelte"

  const TEXT_CONVERSATION = [
    { text: "Hey I found some listings I think we should tour:", side: "left" },
    {
      text: "https://www.zillow.com/homedetails/211-Avenue-A-APT-12-New-York-NY-10009/2131436008_zpid/\nhttps://streeteasy.com/building/107-east-10-street-new_york/3d?featured=1\nhttps://www.apartments.com/penthouse-2-bedroom-1-bath-with-private-ou-new-york-ny/z42cc6x/",
      wrap: true,
      side: "left",
      reaction: "👍",
    },
    { text: "Which one had the outdoor patio?", side: "right" },
    {
      text: "The first link I think?",
      side: "left",
    },
    {
      text: "Wait no it's the second one",
      side: "left",
    },
    {
      text: "Thanks. I emailed asking for the application for these ones:",
      side: "right",
    },
    {
      text: "https://www.apartments.com/casa-bella-new-york-ny/jpvy1f7/\nhttps://www.apartments.com/42-avenue-b-new-york-ny-unit-fl4-id1212/1zrd2t5/\nhttps://streeteasy.com/building/107-east-10-street-new_york/3d?featured=1\nhttps://www.zillow.com/homedetails/40-E-80th-St-APT-25A-New-York-NY-10075/244809381_zpid/\nhttps://www.zillow.com/homedetails/433-W-21st-St-APT-2D-New-York-NY-10011/2054028424_zpid/",
      side: "right",
      wrap: true,
    },
    {
      text: "I just looked up my commute for the last one and it's a no-go for me",
      side: "left",
    },
    {
      text: 'Are you sure these are all 2BD? Also I have "garbage smell" in my notes but can\'t remember which one that was for',
      side: "left",
    },
    {
      text: "Ugh I know all of these are blending together. It's hard to remember which ones check our boxes.",
      side: "right",
    },
    {
      text: "We should really make a spreadsheet...",
      side: "right",
    },
    {
      text: "Lol you know I am not that organized",
      side: "left",
    },
    {
      text: "Yo check out this site I found to do it for us:",
      isLink: true,
      side: "right",
      reaction: "😮",
    },
  ]

  onMount(() => {
    TEXT_CONVERSATION.forEach((message, i) => {
      const selector = `.text-message-${i + 1}`
      scroll(
        animate(
          selector,
          {
            opacity: [0, 1],
            scale: [0.8, 1],
            x: [message.side === "left" ? "-50%" : "50%", 0],
            y: ["50%", 0],
          },
          COMMON_ANIMATION_OPTIONS
        ),
        {
          offset: ["start end", "0.5 0.8"],
          target: document.querySelector(selector) ?? undefined,
        }
      )
    })
  })
</script>

<div class="text-messages">
  {#each TEXT_CONVERSATION as message, i}
    <div
      class={classNames(
        "text-message",
        { wrap: message.wrap },
        `text-message-${i + 1}`,
        {
          "text-message-left": message.side === "left",
          "text-message-right": message.side === "right",
        }
      )}
    >
      {message.text}
      {#if message.isLink}
        <RouterLink to="#">https://getlistable.app</RouterLink>
      {/if}
      {#if message.reaction}
        <span class="reaction">{message.reaction}</span>
      {/if}
    </div>
  {/each}
</div>

<style>
  .text-messages {
    display: flex;
    flex-direction: column;
    margin-top: 200px;
    width: min(90vw, 500px);
    position: relative;
  }
  .text-message {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 18px;
    padding-right: 18px;
    font-weight: 600;
    margin-top: 20px;
    border-radius: 10px;
    min-width: 40%;
    max-width: 70%;
    opacity: 0;
    position: relative;
  }
  .text-message.wrap {
    word-break: break-word;
    hyphens: auto;
    white-space: break-spaces;
  }
  .reaction {
    position: absolute;
    font-size: 25px;
    padding: 5px;
    border-radius: 100%;
    aspect-ratio: 1 / 1;
    width: 30px;
    text-align: center;
    line-height: 10px;
    user-select: none;
  }
  .text-message-right > .reaction {
    bottom: 0;
    left: 0;
    transform: translateX(-50%) translateY(50%);
  }
  .text-message-left > .reaction {
    bottom: 0;
    right: 0;
    transform: translateX(50%) translateY(50%);
  }
  .text-message-left {
    background-color: #006aff;
    color: #fff;
    transform: translateX(-50%) translateY(50%) scale(0.8);
  }
  .text-message-right {
    background-color: var(--secondary-bg);
    align-self: flex-end;
    transform: translateX(50%) translateY(50%) scale(0.8);
  }
</style>
