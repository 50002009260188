import type { PartialRecord } from "@shared/types.ts"
import type { ComponentType, SvelteComponent } from "svelte"
import { readonly, writable } from "svelte/store"
import type { MadeSidepanel } from "./types.ts"

export const createSidepanelManager = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const store = writable<MadeSidepanel<any>[]>([])

  const closeSidepanel = (sidepanelId: string) => {
    store.update((existing) => {
      const index = existing.findIndex(
        (sidepanel) => sidepanel.id === sidepanelId
      )
      if (index < 0) {
        return existing
      }
      const updated = [...existing]
      const sidepanel = updated.splice(index, 1)
      sidepanel[0]?.onClose?.()
      return updated
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const makeSidepanel = <T extends PartialRecord<string, any>>({
    component,
    title,
    nthToFocusFirst,
    side,
  }: {
    component: ComponentType<SvelteComponent<T>>
    title: string
    nthToFocusFirst?: number
    side?: "left" | "right"
  }) => {
    const sidepanelId = crypto.randomUUID()

    return {
      open: ({ props, onClose }: { props: T; onClose?: () => void }) => {
        store.update((existing) => [
          ...existing,
          {
            title,
            nthToFocusFirst,
            component,
            side,
            props,
            id: sidepanelId,
            onClose,
          },
        ])
      },
      close: () => closeSidepanel(sidepanelId),
    }
  }

  const sidepanels = readonly(store)

  return {
    makeSidepanel,
    closeSidepanel,
    sidepanels,
  }
}

export type SidepanelManagerT = ReturnType<typeof createSidepanelManager>
