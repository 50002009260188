<script lang="ts" context="module">
  const DEFAULT_EMPTY_NOTES = "### Nothing here right now"
</script>

<script lang="ts">
  import type { FormatSpec, GenericDataValue, TransitMode } from "@shared/types"
  import { DataFormatOverrideType, type DataFormatOverrides } from "./types"
  import { assertLocationDetails } from "@shared/data/json/location.ts"
  import { assertNearbyPlaces } from "@shared/data/json/nearby_places.ts"
  import { EnumT } from "@shared/schema"
  import Button from "@/lib/Button.svelte"
  import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons"
  import { navigateTo } from "@/lib/RouterView"
  import { globalEditLocationModal } from "@/lib/modals"
  import { Location } from "@shared/util"
  import { TRANSIT_MODE_EMOJI_LOOKUP } from "@shared/data/defaults.ts"
  import DataFormatDisplay from "./DataFormatDisplay.svelte"
  import { assertCommuteDetails } from "@shared/data/json/commute_details.ts"
  import CondWrapper from "@/lib/CondWrapper.svelte"
  import { stopPropagation } from "@/util"
  import { faEye } from "@fortawesome/free-regular-svg-icons"
  import { globalViewMarkdownSidepanel } from "@/lib/sidepanels"
  import { assertMarkdownDetails } from "@shared/data/json/markdown"

  export let textOnly: boolean = false
  export let value: GenericDataValue<EnumT.DataType.json> | undefined
  export let formatSpec: FormatSpec
  export let dataFormatOverrides: DataFormatOverrides
  export const emitEvent = () => {
    if (formatSpec.type === EnumT.FormatSpecType.locationDetails) {
      viewLocation()
    }
  }

  $: viewLocationLink =
    dataFormatOverrides[DataFormatOverrideType.viewLocationLink]
  $: stringified = JSON.stringify(value)

  const viewLocation = () => {
    if (!viewLocationLink) {
      assertLocationDetails(value)
      globalEditLocationModal.open({
        props: {
          initialValue: new Location(value.data),
          readonly: true,
        },
        onAccept: () => {},
      })
    } else {
      navigateTo(viewLocationLink)
    }
  }

  $: commuteDetails = (() => {
    if (
      formatSpec.type !== EnumT.FormatSpecType.commuteDetails ||
      value == null
    ) {
      return undefined
    }
    assertCommuteDetails(value)
    return value.data
  })()
  $: nearbyPlaces = (() => {
    if (
      formatSpec.type !== EnumT.FormatSpecType.nearbyPlaces ||
      value == null
    ) {
      return undefined
    }
    assertNearbyPlaces(value)
    return value.data
  })()
  $: transitModeEmoji = commuteDetails
    ? TRANSIT_MODE_EMOJI_LOOKUP[commuteDetails.transitMode as TransitMode]
    : null
  $: directionsUrl = (() => {
    if (!commuteDetails) {
      return undefined
    }
    const origin = new Location(commuteDetails.origin)
    const destination = new Location(commuteDetails.destination)
    return `https://www.google.com/maps/dir/?api=1&origin=${origin.lat},${origin.lon}&destination=${destination.lat},${destination.lon}&travelmode=${commuteDetails.transitMode}`
  })()

  const onClickViewMarkdown = () => {
    let markdownValue: string
    if (value != null) {
      assertMarkdownDetails(value)
      markdownValue =
        value.data.markdown.trim() !== ""
          ? value.data.markdown
          : DEFAULT_EMPTY_NOTES
    } else {
      markdownValue = DEFAULT_EMPTY_NOTES
    }
    globalViewMarkdownSidepanel.open({ props: { value: markdownValue } })
  }
</script>

{#if value}
  {#if textOnly}
    {stringified}
  {/if}
  {#if formatSpec.type === EnumT.FormatSpecType.locationDetails}
    <Button
      iconLeft={faLocationCrosshairs}
      nofocus
      to={viewLocationLink}
      on:click={viewLocation}
      size="small">View on Map</Button
    >
  {:else if formatSpec.type === EnumT.FormatSpecType.commuteDetails}
    {transitModeEmoji}
    <CondWrapper
      tag="a"
      on:click={stopPropagation()}
      tabindex="-1"
      href={directionsUrl}
      target="_blank"
      rel="noopener noreferrer"
      wrap={!!directionsUrl}
    >
      <DataFormatDisplay
        dataType={EnumT.DataType.number}
        {textOnly}
        formatSpec={{
          type: EnumT.FormatSpecType.duration,
          minimumUnits: EnumT.DurationUnits.minutes,
        }}
        value={commuteDetails?.route.durationSeconds}
      />
    </CondWrapper>
  {:else if formatSpec.type === EnumT.FormatSpecType.nearbyPlaces}
    <DataFormatDisplay
      dataType={EnumT.DataType.number}
      {textOnly}
      formatSpec={{ type: EnumT.FormatSpecType.none }}
      value={nearbyPlaces?.places.length}
    />
  {:else if formatSpec.type === EnumT.FormatSpecType.markdown}
    <Button size="small" iconLeft={faEye} on:click={onClickViewMarkdown}
      >View</Button
    >
  {:else}
    <textarea readonly value={stringified} />
  {/if}
{/if}
