<script lang="ts">
  //
</script>

<div class="skeleton-bar" />

<style>
  @keyframes skeletal {
    0% {
      background-position: 200% 0%;
    }
    40% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
  .skeleton-bar {
    background: linear-gradient(
      90deg,
      var(--secondary-bg) 0%,
      var(--primary-accent) 50%,
      var(--secondary-bg) 80%,
      var(--secondary-bg) 100%
    );
    background-size: 200% 100%;
    animation: skeletal 0.9s linear infinite;
    border-radius: var(--default-rounding);
    width: 100%;
    min-height: 20px;
    flex: 1;
  }
</style>
