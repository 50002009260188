<script lang="ts">
  import MainNav from "@/lib/MainNav.svelte"
  import RouterLink from "@/lib/RouterView/RouterLink.svelte"
  import SpacingWrapper from "@/lib/layout/SpacingWrapper.svelte"
  import { privacyRequestRoute } from "@/routes"
</script>

<main>
  <MainNav />
  <div class="container">
    <SpacingWrapper sides={["top", "bottom"]}>
      <p>
        Below you will find our full, legalese, privacy policy. This document
        was generated using a privacy policy generation service. Eventually it
        would be great to draft a simpler document. In the meantime, however, we
        can clarify some details upfront. The list of measures and services we
        use is not guaranteed to be exhaustive, but if we can clarify anything
        please <RouterLink to={privacyRequestRoute()}>contact us</RouterLink>.
      </p>

      <h1>Advertising</h1>
      <p>
        As of writing, we do not use any third-party ad networks on the Listable
        website. We may purchase ads externally to advertise the product.
      </p>

      <h1>Analytics</h1>
      <p>
        Like most websites, we record certain analytic events. We use <a
          href="https://plausible.io/sites">Plausible</a
        >
        to tell us things like how many people are visiting the site, and rougly
        how they are finding it. We chose Plausible due to their commitment to user
        privacy. You can read more about how Plausble respects your privacy
        <a href="https://plausible.io/privacy-focused-web-analytics">here</a
        >.<br /><br />We do not currently have an internal setting to disable
        Plausible on a per-user basis. We do not take any measures to bypass
        adblockers. Based on internal testing we recommend
        <a href="https://github.com/gorhill/uBlock">uBlock Origin</a>
        to avoid loading the analytics code.<br /><br />In addition to
        Plausible, we utilize internal scripts that send us notifications via
        <a href="https://telegram.org/">Telegram</a> when certain things happen on
        the site, such as when you create a new list. This is a great dopamine boost
        for us.
      </p>

      <h1>Cookies</h1>
      <p>
        We use single-party HTTP cookies for authentication purposes only. We do
        not read any third-party cookies nor share your data via cookies with
        any other party.
      </p>

      <h1>Logging</h1>
      <p>
        We don't write perfect code- we use <a href="https://sentry.io/welcome/"
          >Sentry</a
        >
        to tell us when something breaks. Sentry attempts to avoid collecting Personal
        Identifiable Information (PII), and we take a few extra measures to reduce
        the information collected. Before any data is sent to their servers, we scrub
        it with the following snippet of code:<br /><br />
        <code
          >beforeSend: (event) => {"{"} delete event.user; delete event.contexts;
          return event
          {"}"}
        </code>
      </p>

      <h1>Privacy Policy</h1>
    </SpacingWrapper>
    <iframe src="/privacy.html" class="iframe" title="Privacy Policy" />
  </div>
</main>

<style>
  .container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    max-width: 800px;
  }
  .iframe {
    width: 100%;
    height: calc(100vh - 200px);
  }
</style>
