import type {
  GenericDataValue,
  JsonDataType,
  MarkdownDetails,
} from "../../types"

export type MarkdownDetailsData = JsonDataType<MarkdownDetails>

export function assertMarkdownDetails(
  v: GenericDataValue | undefined
): asserts v is MarkdownDetailsData {
  if ((v as MarkdownDetailsData).data.markdown == null) {
    throw `invalid location data ${v?.toString()}`
  }
}
